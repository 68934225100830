import { Controller } from 'stimulus'

import Swiper, { Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use(Pagination)

export default class extends Controller {
  static targets = ['pagination']

  connect() {
    new Swiper(this.element, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 32,
      pagination: {
        el: this.paginationTarget,
        clickable: true,
        dynamicBullets: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
      },
    })
  }
}
