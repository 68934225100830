import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

import NestedForm from 'stimulus-rails-nested-form'
import Flatpickr from 'stimulus-flatpickr'
import { RemoteController } from 'stimulus-remote'

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)

application.load(definitionsFromContext(context))

application.register('flatpickr', Flatpickr)
application.register('nested-form', NestedForm)
application.register('remote', RemoteController)

require('flatpickr/dist/flatpickr.css')
