import { Controller } from 'stimulus'

import Swiper, { Navigation } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use(Navigation)

export default class extends Controller {
  static targets = ['container', 'prev', 'next']

  connect() {
    new Swiper(this.containerTarget, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 32,
      navigation: {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
      },
    })
  }
}
