import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['income', 'deposit', 'property', 'monthly']

  connect() {
    this.calculate()
  }

  calculate() {
    const income = this.incomeTarget.value
    const deposit = this.depositTarget.value

    this.print(income, deposit)
  }

  print(income, deposit) {
    this.propertyTarget.innerText = this.money(income)
    this.monthlyTarget.innerText = this.money(deposit)
  }

  // private

  money(value) {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      maximumSignificantDigits: 3,
    }).format(value)
  }
}
