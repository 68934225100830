import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['price', 'deposit', 'mortgage', 'interest', 'repayment']

  connect() {
    this.calculate()
  }

  calculate() {
    const price = this.priceTarget.value
    const deposit = this.depositTarget.value
    const rate = 2
    const term = 30

    this.price = price

    this.mortgageCost(price, deposit)
    this.repaymentCost(rate, term)
    this.interestCost(rate)
  }

  // private

  mortgageCost(price, deposit) {
    const value = price - deposit

    this.mortgage = value.toFixed(2)
    this.mortgageTarget.innerText = value ? this.money(value) : '---'
  }

  interestCost(rate) {
    const p = this.mortgage
    const i = rate
    const cost = ((p / 100) * i) / 12

    this.interest = cost.toFixed(2)
    this.interestTarget.innerText = cost ? this.money(cost) : '---'
  }

  repaymentCost(rate, term) {
    const p = this.mortgage
    const i = rate / 100 / 12
    const n = term * 12

    const cost = (p * i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1)

    this.repayment = cost.toFixed(2)
    this.repaymentTarget.innerText =
      cost && cost !== Infinity ? this.money(cost) : '---'
  }

  money(value) {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      maximumSignificantDigits: 3,
    }).format(value)
  }
}
