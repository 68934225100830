import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['label']

  click(event) {
    this.check(event.currentTarget.value)
  }

  check(value) {
    const label = document.querySelector(`label[data-value="${value}"]`)

    for (const label of this.labelTargets) {
      label.classList.remove('checked')
    }

    label.classList.add('checked')
  }
}
