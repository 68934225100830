import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['link']

  connect() {
    const hash = window.location.hash.replace('#', '')

    if (hash) {
      for (let link of this.linkTargets) {
        if (link.dataset.id === hash) link.classList.add('active')
      }
    }
  }

  click(event) {
    event.preventDefault()

    const current = event.currentTarget
    const id = current.dataset.id
    const element = document.getElementById(id)

    for (let link of this.linkTargets) {
      link.classList.remove('active')
    }

    current.classList.add('active')

    element.scrollIntoView({
      behavior: 'smooth',
    })

    window.location.hash = id
  }
}
