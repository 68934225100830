import { Controller } from 'stimulus'

import Swiper, { Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use(Autoplay)

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      loop: true,
      slidesPerView: 2,
      spaceBetween: 16,
      autoplay: {
        delay: 10000,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 6,
        },
      },
    })
  }
}
