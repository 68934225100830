import { Controller } from 'stimulus'

import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 64,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
      },
    })
  }
}
